import LoadingSpinner from "@components/loadingSpinner";
import { TicketIcon } from "@heroicons/react/20/solid";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import * as Popover from "@radix-ui/react-popover";
import { BasketSummary } from "@types";
import { BASE_URL, COUNTRY_CODE } from "@utils/constants";
import { getCookieValue, getCurrencyFormat } from "@utils/helpers";
import { useInterval, usePrevious } from "@utils/hooks";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { mutate } from "swr";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  basket: BasketSummary;
  isPended: boolean;
}

export default function Cart({ open, setOpen, basket, isPended }: Props) {
  const router = useRouter();
  const [count, setCount] = useState(basket.Ttl);
  const timer = new Date(count * 1000).toISOString().slice(14, 19);
  const basketSubtotal = basket.Groups.reduce(
    (prev, curr) => prev + curr.Subtotal,
    0
  );
  const prevOpen = usePrevious(open);

  const NZDollar = Intl.NumberFormat("en-NZ", {
    style: "currency",
    currency: "NZD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useInterval(
    () => {
      if (count > 0 && !isPended) {
        setCount(count - 1);
      }
    },
    count > 0 ? 1000 : 0
  );

  useEffect(() => {
    async function handleDelete() {
      const basketKey = getCookieValue("ASP.NET_SessionId");
      if (!basketKey) return;
      const res = await fetch(
        `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}`,
        {
          method: "DELETE",
          headers: {
            "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID!,
          },
        }
      );
      if (res.status === 200) {
        await axios.delete("/api/basket");
        await mutate(`basket/${basketKey}`);
        await mutate(`basket/${basketKey}/summary`);
        await mutate(`basket/${basketKey}/checkout`);
        await mutate(
          (key: any) => Array.isArray(key) && key[0].startsWith("events/"),
          undefined,
          {
            revalidate: true,
          }
        );
        await mutate(
          (key) => typeof key === "string" && key.startsWith("events/"),
          undefined,
          {
            revalidate: true,
          }
        );
        router.push("/cart?expired=true");
      }
    }

    if (count <= 0) {
      handleDelete();
    }
  }, [count, router]);

  useEffect(() => {
    if (isPended) {
      setOpen(false);
    }
  }, [isPended, setOpen]);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger
        data-testid="cartbutton"
        disabled={isPended}
        className="flex gap-1 ml-4 px-4 py-2 items-center rounded-full bg-white disabled:bg-gray-200 focus:outline-none hover:bg-indigo-50 focus:bg-indigo-50 transition-colors duration-200 ease-in-out"
      >
        <div className="relative">
          <ShoppingCartIcon className="h-6 w-6 text-black" />
          {basket.Quantity > 0 ? (
            <span className="absolute flex justify-center items-center px-1 -top-1.5 -right-2 min-w-[1rem] text-xs rounded-full bg-indigo-100 text-indigo-900 tabular-nums">
              {basket.Quantity}
            </span>
          ) : (
            <></>
          )}
        </div>
        {isPended ? (
          <span className="ml-1">
            <LoadingSpinner size="18" />
          </span>
        ) : (
          <span className="text-black tabular-nums">{timer}</span>
        )}
      </Popover.Trigger>
      <AnimatePresence>
        {open && (
          <Popover.Portal forceMount>
            <Popover.Content
              data-testid="cartcontent"
              className="relative z-50"
              sideOffset={5}
              align="end"
              alignOffset={-64}
              forceMount
            >
              <motion.div
                initial={prevOpen === false ? "hidden" : "show"}
                animate="show"
                exit="hidden"
                variants={{
                  hidden: {
                    opacity: 0,
                    y: "-1rem",
                    transition: { duration: 0.125 },
                  },
                  show: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.25 }}
                className="relative rounded-2xl m-2 p-4 bg-white max-h-[calc(100dvh-120px)] w-[calc(100dvw-16px)] sm:w-96 shadow ring-1 ring-inset ring-[#E5E7EB]"
              >
                <h2 className="text-2xl font-black text-black">Cart</h2>
                <div className="flex flex-col gap-2 max-h-[calc(100dvh-328px)] overflow-y-auto">
                  {basket.Groups.map((group) => (
                    <div key={group.ShowingId}>
                      <h3 className="text-lg font-semibold text-black">
                        {group.Event}
                      </h3>
                      <p className="text-sm text-gray-500">{group.Showing}</p>
                      <div className="flex flex-col gap-2 py-2">
                        {group.Items.map((item, index) => (
                          <div
                            key={`${item.PriceId}-${item.UniqueId}-${index}`}
                            className="flex items-center justify-between p-2 bg-indigo-100 border border-indigo-200 rounded-xl"
                          >
                            <p className="font-black text-sm leading-none text-indigo-800">
                              {item.Name}
                            </p>
                            <div className="flex flex-col gap-4 justify-between items-end shrink-0">
                              <p className="text-sm font-medium leading-none text-indigo-800">
                                {item.Cost === 0
                                  ? "Free"
                                  : getCurrencyFormat(item.Cost)}
                              </p>
                              <div className="flex gap-1 items-center px-1 py-0.5 bg-indigo-600 text-indigo-100 rounded-lg">
                                <TicketIcon className="w-5 h-5 -rotate-12" />
                                <span className="font-medium text-sm">
                                  x{item.Quantity}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex items-baseline justify-between mt-4 py-4 border-t">
                  <p className="text-lg font-semibold text-black">Subtotal:</p>
                  <p className="text-3xl font-black text-black">
                    {NZDollar.format(basketSubtotal)}
                  </p>
                </div>
                <Link
                  data-testid="viewcart"
                  href="/cart"
                  onClick={() => setOpen(false)}
                  className="flex items-center justify-center w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 text-white font-semibold transition-colors duration-200 ease-in-out"
                >
                  Checkout
                </Link>
              </motion.div>
            </Popover.Content>
          </Popover.Portal>
        )}
      </AnimatePresence>
    </Popover.Root>
  );
}
